<template>
  <Modal
    :activeModal="isActiveModal"
    @closeModal="closeModal"
    :widthModal="750"
  >
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ data.description }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-card
        class="white--text align-end pa-2"
      >
        <img
          :src="data.image" aspect-ratio="1"
          style="max-width: 100%;"
          v-if="data.type !== 'application/pdf'"
        />

        <iframe v-else :src="data.image" width="100%" height="300px" style="border: none;"/>
      </v-card>
    </v-card>
  </Modal>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
export default {
  name: 'ModalFoto',
  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: (() => {})
    }
  },
  components:{
    Modal: () => import('@/views/components/modal')
  },
  data: () => ({
  }),
  computed: {
    variables: () => variables,
  },
  methods: {
    closeModal () {
      this.$emit('closeModal', false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}
</style>
